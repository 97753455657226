
























































































































import { computed, defineComponent, nextTick, onMounted, ref, onUpdated } from '@vue/composition-api';
import TrackingUtils from '@/utils/tracking/tracking.utils';
import TeaserTrackingUtils from '@/utils/tracking/teaserTracking.utils';
import { addAdSlot, removeAdSlot } from '@/utils/ads/BFAAds';
import * as ivw from '@/utils/ivw.utils';
import CommonUtils from '@/utils/common.utils';

export default defineComponent({
  name: 'gallery-slider',
  props: {
    gallery: {
      type: Array as () => Array<any>,
      required: true,
    },
    galleryName: {
      type: String,
      required: true,
    },
    galleryId: {
      type: String,
      required: true,
    },
    inArticle: {
      type: Boolean,
    },
    adOrientation: {
      type: String,
      required: true,
    },
    relatedGalleryUuid: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const infoRef = ref<HTMLElement | null>(null);
    const galleryContainerRef = ref<HTMLElement | null>(null);
    const infoDivHeight = ref(0);
    const calcInfoHeight = () => {
      nextTick(() => (infoDivHeight.value = infoRef.value?.scrollHeight || 0));
    };
    const infoDivStyle = computed(() => `height: ${infoDivHeight.value ? `${infoDivHeight.value}px` : '0px'};`);

    const currentIndex = ref(0);
    const animDirection = ref('slide');
    const adSlotRef = ref('');
    const slideshowMaxIndex = ref(props.gallery.length - 1);

    const currentImage = computed(() => props.gallery[currentIndex.value]);
    const activeImageStyle = computed(() => `background-image: url("${currentImage.value.url}");`);
    const blurImageStyle = computed(
      () => `${CommonUtils.isSafari() ? currentImage.value.url : currentImage.value.urlWebp || currentImage.value.url}`
    );

    const handleNewSlide = () => {
      calcInfoHeight();
      if (adSlotRef.value) {
        removeAdSlot(adSlotRef.value);
        adSlotRef.value = '';
      }
      if (currentImage.value.ad) {
        const adSlotId = `gallery-id-${currentIndex.value}`;
        nextTick(() => {
          addAdSlot(adSlotId, props.adOrientation, currentImage.value.adIndex);
          adSlotRef.value = adSlotId;
        });
      } else {
        TrackingUtils.trackEvent({
          event: 'b_gallery_view',
          eventCategory: 'gallery.view',
          eventAction: props.galleryName,
          eventLabel: `pic_${currentImage.value.index + 1}`,
        });
        ivw.pageView();
        TrackingUtils.trackPageView(location.pathname);
      }
    };

    const imageClick = (event: any) => {
      if (currentIndex.value === slideshowMaxIndex.value) {
        event.stopPropagation();
        return;
      }

      if (galleryContainerRef.value?.clientWidth) {
        if (event.offsetX < galleryContainerRef.value?.clientWidth / 2) {
          prevSlide();
        } else {
          nextSlide();
        }
      }
    };

    const prevSlide = () => {
      animDirection.value = 'slideback';
      if (currentIndex.value === 0) {
        currentIndex.value = slideshowMaxIndex.value;
      } else {
        currentIndex.value--;
      }
      handleNewSlide();
    };

    const nextSlide = () => {
      animDirection.value = 'slide';
      if (currentIndex.value < slideshowMaxIndex.value) {
        currentIndex.value++;
      } else if (currentIndex.value === slideshowMaxIndex.value && props.relatedGalleryUuid) {
        window.location.href = `/gallery/${props.relatedGalleryUuid}.html`;
      } else {
        currentIndex.value = 0;
      }
      handleNewSlide();
    };

    onMounted(() => {
      calcInfoHeight();
      TeaserTrackingUtils.manualTeaserTracking('open_gallery', `/gallery/${props.galleryId}.html`);
      TrackingUtils.trackEvent({
        event: 'b_gallery_open',
        eventCategory: 'gallery.open',
        eventAction: props.galleryName,
      });
      TrackingUtils.trackEvent({
        event: 'b_gallery_view',
        eventCategory: 'gallery.view',
        eventAction: props.galleryName,
        eventLabel: `pic_${currentImage.value.index + 1}`,
      });
      ivw.pageView();
      TrackingUtils.trackPageView(location.pathname);
    });

    return {
      infoRef,
      galleryContainerRef,
      infoDivHeight,
      infoDivStyle,
      blurImageStyle,
      currentImage,
      currentIndex,
      animDirection,
      adSlotRef,
      activeImageStyle,
      slideshowMaxIndex,
      prevSlide,
      nextSlide,
      imageClick,
    };
  },
});
